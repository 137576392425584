import { Flex, Text } from "shared/ui";
/* eslint-disable consistent-return */
import styled from "styled-components";

const Cycles = ({ items = [], selectedCycle = "", onSelect = () => { } }) => (
  <Flex flexDirection="row" flexWrap="wrap">
    {items.map((item) => (
      <Cycle
        key={item.id}
        isSelected={item.cycle === selectedCycle}
        onClick={() => onSelect(item.cycle)}
      >
        <Text variant="body1" color="color7">
          {item.text}
        </Text>
      </Cycle>
    ))}
  </Flex>
);

const Cycle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  max-height: 48px;
  margin-right: 5px;
  border: ${({ isSelected }) =>
    isSelected ? "2px solid #2D8AF6" : "1px solid #CCCCCC"};
  box-sizing: border-box;
  background: #ffffff;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }
`;

export default Cycles;
