import { getUserRole } from "shared/libs/jwt-decode";
import { Flex, Text } from "shared/ui";
import styled from "styled-components";

import {
  AddressSearch,
  CommandDropdown,
  Cycles,
  ManagerDropdown,
  RouteDropdown,
  VisitDays,
} from "./model";

// {role === "ROUTE_ENG" ? <RouteEngineerRouteDropdown /> : <RouteDropdown />}

const Filters = () => {
  const role = getUserRole();
  return (
    <>
      <Root>
        <Flex flex={2} mr="12px">
          <Flex flexDirection="row" mb="4px">
            <Text variant="body3" color="color3">
              {role === "ROUTE_ENG" ? "Команда" : "Менеджер"}
            </Text>
          </Flex>
          {role === "ROUTE_ENG" ? <CommandDropdown /> : <ManagerDropdown />}
        </Flex>
        <Flex flex={3} mr="12px">
          <Flex flexDirection="row" mb="4px">
            <Text variant="body3" color="color3">
              Маршрут
            </Text>
          </Flex>
          <RouteDropdown />
        </Flex>
        <Flex flex={2} mr="12px">
          <Flex flexDirection="row" mb="4px">
            <Text variant="body3" color="color3">
              Поиск
            </Text>
          </Flex>
          <AddressSearch />
        </Flex>
        <Flex mr="12px">
          <Flex flexDirection="row" mb="4px">
            <Text variant="body3" color="color3">
              День визита
            </Text>
          </Flex>
          <VisitDays />
        </Flex>

      </Root>
      <Flex mt='10px'>
        <Flex flexDirection="row" mb="4px">
          <Text variant="body3" color="color3">
            Цикл
          </Text>
        </Flex>
        <Cycles />
      </Flex>
    </>

  );
};

const Root = styled.div`
  display: flex;
  align-items: center;
`;

export default Filters;
